import React from 'react';
import "./index.css";
import { Link } from 'react-router-dom';
import { APIURL, BASEURL } from '../../utils/APPADATAURLS';
import CustomButton from '../CustomButton';
import { Modal, Toast } from 'react-bootstrap';
import { useState } from 'react';
import RateProduct from '../Rating/RateProduct';
import { FaBookmark, FaChevronDown, FaDiceOne, FaMarkdown } from 'react-icons/fa';
import { FiX } from 'react-icons/fi';
import { AiOutlineArrowRight, AiOutlineDown, AiOutlineUp } from 'react-icons/ai';

const Order = ({order,refresh,setRefresh}) => {
  console.log(setRefresh);
  const [modalShown,setModalShown] = useState(false);
  const [productIndex,setProductIndex] = useState(0);
  const [imageLoaded,setImageLoaded] = useState(false);

  const [showDetails,setShowDetails] = useState({
    payment:false,
    items:false
  });

 

  const [received,setReceived] = useState({
            loading:false,
            error:false,
            message:"",
            results:null
        });
  const markAsReceived = ()=>{
       setReceived({
            loading:true,
            error:false,
            message:"",
            results:null
        });

    let formData = new FormData();
    formData.append("mark-as-received","mark");
    formData.append("orderID",order.id);

    fetch(APIURL,{
        method:"POST",
        body:formData
    })
    .then(req=>req.json())
    .then(response=>{
        setReceived({
            loading:false,
            error:response.error,
            message:response.message,
            results:response.data});
            setRefresh(!refresh);
    }).catch(err=>{
        console.log(err);
        setReceived({
            loading:false,
            error:true,
            message:"An error occurred! Try again later",
            results:null});
    });
}



  return (
    <div className='order' 
    style={{
      position:"relative",
      transition:"height .5 ease-in-out",
      height:"auto"
    }} >
      <Toast show={received.error} onClose={()=>{
            setReceived({...received,error:false,message:""});
        }}
        
        style={{
            position:"fixed",
            top:0,
            right:0,
            zIndex:300,
            backgroundColor:"red"
        }}
        
        > 
        <Toast.Header closeButton />
            <Toast.Body>
                {
                    received.message
                }
            </Toast.Body>
      </Toast>
      
      <div
        style={{
            position:"absolute",
            right:5,
            top:5,
            display:'flex',
            flexDirection:"row",
            alignItems:"center",
            gap:10,
            color:order.isDelivered?"green":"gray",
            fontSize:14,
            fontWeight:700,
        }}
        >
            {order.isDelivered?"Delivered":"Pending"}
            {order.status=="Canceled"?<FiX/>:order.status=="Pending"?<FaBookmark/>:<FaDiceOne/>}
        </div>
      <Modal
      show={modalShown}
      centered
      onHide={()=>setModalShown(false)}
      onBackdropClick={()=>setModalShown(false)}
      >
          <Modal.Header closeButton >
            Rate {order.data[productIndex].productName}
          </Modal.Header>
          <Modal.Body>
            <RateProduct product={order.data[productIndex]} setModalShown={setModalShown}/>
          </Modal.Body>
      </Modal>

        <label className='title' >Order : {order.orderNumber}</label>
        <label>Status: {order.status}</label>
        
        <label>No. of items: {order.data.length} items</label>
        <label>Payment : {order.isPaid?"Paid":"Not paid"}</label>

        <div className='more'>
          <Link className='more-link' to={`/profile/orders/${order.id}`}>More details <AiOutlineArrowRight/></Link>
        </div>
        
    </div>
  )
}

export default Order;