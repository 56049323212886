import React, { useContext } from 'react';
import {useParams,useNavigate, useLocation, Link} from "react-router-dom";
import { AddToBag, Advert, BottomTabs, CustomButton, Footer, Header, HeaderHero, HorizontalProductsBanner, ProductsBanner, SmallScreenHeader } from '../../components';

import "./index.css";
import { FaChevronRight, FaMailBulk, FaPhone, FaShare, FaSitemap } from 'react-icons/fa';
import { useEffect } from 'react';
import { useState } from 'react';
import { APPDATAURLS, BASEURL } from '../../utils/APPADATAURLS';
import { FiClipboard, FiCopy, FiPlay, FiStar } from 'react-icons/fi';
import { CartContext, ProductSelectedContext } from '../../contexts';
import  Lottie  from 'lottie-react';
import { Helmet } from 'react-helmet-async';
import { ProductLoading } from '../../components/Loading';
import { AiOutlineShareAlt } from 'react-icons/ai';
import { Modal, Toast } from 'react-bootstrap';
import { useSEO } from '../../hooks';

const ProductPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const {productID} = useParams();
  const [imageLoaded,setImageLoaded] = useState(false);
   const [showVideo,setShowVideo] = useState(false);
  const [cartData,setCartData] = useContext(CartContext);
  const [selectedImageIndex,setSelectedImageIndex] = useState(0);
  const [selectdeProductData,setSelectdeProductData] = useContext(ProductSelectedContext);
  const [toastMessage,setToastMessage] = useState({
    shown:false,
    message:""
  });

  const [modalMessage,setModalMessage] = useState({
    shown:false,
    message:""
  });

  const viewShop = ()=>{
    navigate("/shops/1")
  }

  const removeFromCart = (itemID)=>{
        let data = [...cartData];
        for(let i=0;i<data.length;i++){
            if(data[i].id == itemID ){
                data.splice(i,1);
                setCartData(data)
                localStorage.setItem("cartData",JSON.stringify(data));
                return;
            }
        }
    }

  const increaseQuantity = (itemID)=>{
        let data = [...cartData];
        for(let i=0;i<data.length;i++){
            if(data[i].id == itemID ){
                data[i].quantity+=1;
                setCartData(data);
                localStorage.setItem("cartData",JSON.stringify(data));
                return;
            }
        }
    }

  const inCart = (itemID)=>{
    let data = [...cartData];
    for(let i=0;i<data.length;i++){
      if(data[i].id == itemID){
        return true;
      }
    }
    return false;
  }

    const decreaseQuantity = (itemID)=>{
        let data = [...cartData];
        for(let i=0;i<data.length;i++){
            if(data[i].id == itemID ){
                data[i].quantity-=1;
                if(data[i].quantity == 0){
                    removeFromCart(data[i].id);
                    return;
                }
                setCartData(data);
                localStorage.setItem("cartData",JSON.stringify(data));
                return;
            }
        }
    }

  const addProductToCart = ()=>{
    if(inCart(productID)){
      increaseQuantity(productID);
      return;
    }
    setCartData([...cartData,{
                productName:product.data.productName,
                id:product.data.id,
                quantity:1,
                productPrice:product.data.hasDiscount?
                  Number(product.data.priceAfterDiscount.replace(",","")):
                  product.data.productPrice,
                productImage:product.data.productImage }]);
    localStorage.setItem("cartData",
        JSON.stringify([...cartData,{
                          productName:product.data.productName,
                          id:product.data.id,
                          quantity:1,
                          productPrice:product.data.hasDiscount?
                            Number(product.data.priceAfterDiscount.replace(",","")):
                            product.data.productPrice,
                          productImage:product.data.productImage }]));
  }

  const [product,setProduct] = useState({
    data:{},
    loading:false,
    error:false,
    message:""
  });

  const fetchProduct = ()=>{
    setProduct({
      data:{},
      loading:true,
      error:false,
      message:""
      });
    fetch(`${APPDATAURLS.product}${params.productID}&userID=`,{
      method:"get",
    }).then(req=>req.json()).
    then(response=>{
      setProduct({
      data:response.data,
      loading:false,
      error:response.error,
      message:response.message
      });
    }).catch(err=>{
      console.log(err);
      setProduct({
      data:{},
      loading:false,
      error:true,
      message:err.message
      });
    })
  }

  const share = ()=>{
    
  }

  useSEO({title:product.data?.productName,image:product.data?.productImage});

  useEffect(()=>{
    fetchProduct();
    window.scroll(0,0);
  },[location]);
  return (
    <div
    className="body"
    style={{
      position:"relative"
    }}
    >
      <Toast 
      show={toastMessage.shown}
      onClose={()=>setToastMessage({message:"",shown:false})}
      style={{
        position:"fixed",
        right:0,
        top:0,
        zIndex:200
      }}
      >
        <Toast.Header closeButton >Info</Toast.Header>
        <Toast.Body>
          {toastMessage.message}
        </Toast.Body>
      </Toast>

      <Modal 
      show={modalMessage.shown}
      onHide={()=>setModalMessage({message:"",shown:false})}
      size='xl'
      onBackdropClick={()=>setModalMessage({message:"",shown:false})}
      centered
      >
        <Modal.Header closeButton >Description</Modal.Header>
        <Modal.Body>
          {modalMessage.message}
        </Modal.Body>
      </Modal>



      <Header/>
      <div className="body-wrapper">
        <Advert/>
      </div>
      <SmallScreenHeader backgroundColor='transparent' />
      {
        product.error?
        <div
         className="product_container"
        ></div>:
        product.loading?
        <div
         className="product_container loading-container"
        >
          {/*<Lottie style={{
                width:50,
                height:50
            }} loop animationData={require("../../assets/lottie_animations/circle_progress.json")}/>*/}
           <ProductLoading/>
        </div>:
        product.data?
        <div
      className="product_container"
      >
        
        <div className="product_data">
          

          <div className="product_image" >
            {
              showVideo?
              <video  
                src={`${BASEURL}/${product.data.productVideo}`} 
                controls
                autoPlay
                ></video>:
                <>
                  <img 
                    src={`${BASEURL}/${selectedImageIndex?product.data.productImages[selectedImageIndex]:product.data.productImage}`}
                    style={{
                      display:imageLoaded?"flex":"none",
                    }}
                    onLoad={(e)=>{
                    setImageLoaded(true)}}
                    onError={(e)=>{
                    setImageLoaded(false)}}
                                        />
                  <img
                      style={{
                          display:imageLoaded?"none":"flex",
                      }}
                      src={`/assets/images/savo_logo.jpg`}
                      className='cart-item-image-default'
                  />
                </>
            
            }
            
              <div className='small-images' >
                    
                  {
                    
                    !product.data.productImages?
                    <img 
                      className='isActive'
                      src={`${BASEURL}/${product.data.productImage}`}
                  />: Array.isArray(product.data.productImages) && product.data.productImages.length>0?
                     product.data.productImages.map((image,index)=>{
                      return (
                        <img 
                            onClick={()=>{
                              setSelectedImageIndex(index);
                              setShowVideo(false);
                            }}
                            key={index}
                            src={`${BASEURL}/${image}`}
                            className={selectedImageIndex==index?"isActive":null}
                        />
                      )
                    }):
                    <img 
                      className='isActive'
                      src={`${BASEURL}/${product.data.productImage}`}
                      onClick={()=>{
                              setSelectedImageIndex(null);
                              setShowVideo(false);
                            }}
                  />
                  }
                  {
                    product.data.productVideo?

                    <div style={{position:"relative"}}>
                      <img className={showVideo?"isActive":""} 
                      src={`${BASEURL}/${product.data.productImage}`}
                      onClick={()=>{
                              setSelectedImageIndex(null);
                              setShowVideo(true);
                            }}
                    />
                    <FiPlay
                    onClick={()=>{
                              setSelectedImageIndex(null);
                              setShowVideo(true);
                            }}
                    style={{
                      position:"absolute",
                      left:"50%",
                      top:"50%",
                      transform:"translateX(-50%) translateY(-50%)",
                      color:"var(--app-color)"
                    }} 
                    size={30}
                    />
                    </div>:
                    <></>
                  }
                    
              </div>
          </div>

          <div className='small-screen-small-images' >
                  {
                    
                    !product.data.productImages?
                    <img 
                    onClick={()=>{
                              setSelectedImageIndex(null);
                              setShowVideo(false);
                            }}
                    className={selectedImageIndex==null?"isActive":null}
                    src={`${BASEURL}/${product.data.productImage}`}
                  />: Array.isArray(product.data.productImages) && product.data.productImages.length>0?
                     product.data.productImages.map((image,index)=>{
                      return (
                        <img 
                            onClick={()=>{
                              setSelectedImageIndex(index);
                              setShowVideo(false);
                            }}
                            key={index}
                            src={`${BASEURL}/${image}`}
                            className={selectedImageIndex==index?"isActive":null}
                        />
                      )
                    }):
                    <img 
                    className='isActive'  
                    src={`${BASEURL}/${product.data.productImage}`}
                    onClick={()=>{
                              setSelectedImageIndex(null);
                              setShowVideo(false);
                            }}
                  />

                  }

                  {
                    product.data.productVideo?

                    <div style={{position:"relative"}}>
                      <img className={showVideo?"isActive":""} 
                      src={`${BASEURL}/${product.data.productImage}`}
                      onClick={()=>{
                              setSelectedImageIndex(null);
                              setShowVideo(true);
                            }}
                    />
                    <FiPlay style={{
                      position:"absolute",
                      left:"50%",
                      top:"50%",
                      transform:"translateX(-50%) translateY(-50%)",
                      color:"var(--app-color)"
                    }}

                    onClick={()=>{
                              setSelectedImageIndex(null);
                              setShowVideo(true);
                              
                            }}
                    size={30}
                    />
                    </div>:
                    <></>
                  }
                    
          </div>

          <div className="seller">
            <div>
              <p className='name' >{product.data.productName}</p>
              <p className='subName' >
                {
                product.data?.productDescription?.length>200?
                <>
                {product.data.productDescription.substring(0,200)}
                ...<label style={{color:"var(--app-color)"}} 
                
                onClick={(e)=>{
                  setModalMessage({message:product.data.productDescription,shown:true});
                }}

                >View more</label>
                </>:
                product.data.productDescription  
              }</p>
              <div className='pricing' >
                <label 
                  className='price' 
                  style={{
                    textDecoration:product.data.hasDiscount?"line-through":"none",
                    color:product.data.hasDiscount?"grey":""
                  }}
                  >Ksh.{product.data.productPrice}</label>
                {
                  product.data.hasDiscount?
                  <label
                    className='price'
                >Ksh.{product.data.priceAfterDiscount}</label>:<></>
                }
              </div>
              <div 
              className='pricing'
              >
                
                <div>
                  <Link className='from' to={`/shop/${product.data.categoryName}/${product.data.categoryID}`} >
                    {product.data.categoryName} from 
                  </Link>
                  <Link className='to' to={`/company/${product.data.companyName}/${product.data.companyID}`}> {product.data.companyName}</Link>
                </div>
              </div>
              <div
              className='shipping'
              >
                <label>Shipping info</label>
                <FaChevronRight onClick={()=>navigate("/profile/addresses")} />
              </div>
               <div
                className='rating'
              >
                <div>
                  <label>Ratings</label>
                  <label style={{
                    gap:5,
                    display:"flex",
                    flexDirection:"row",
                    marginLeft:5,
                    marginTop:5,
                    marginBottom:30
                    }}    >
                      {
                          [1,2,3,4,5].map((item,index)=>{
                              return <FiStar 
                              key={index}
                              color={item<=product.data.rating?'var(--app-color)':'#b9b4b4'}  
                              fill={item<=product.data.rating?'var(--app-color)':'#b9b4b4'} 
                              size={15} />
                          })
                      }
                  </label>
                </div>

                <FaChevronRight onClick={()=>navigate(`/products/rating/${product.data.id}`)} />
              </div>
              <div
              style={{
                width:"100%",
                display:"flex",
                flexDirection:"row",
                alignItems:"center",
                justifyContent:"space-between"
              }}
              >
                <div/>
                <div style={{
                margin:5
              }} >

                <AiOutlineShareAlt 
                style={{
                  transform:"rotate(180deg)"
                }}
                color='var(--app-color)' size={30} onClick={(e)=>{
                  window.navigator.share({
                    title:product.productName,
                    text:product.productDescription,
                    url:window.location.href
                  });
                }} />

                <FiClipboard
                style={{
                  marginLeft:10
                }}
                color='var(--app-color)' 
                size={25} 
                onClick={(e)=>{
                  setToastMessage({message:"Product link copied to clip board",shown:true});
                  
                  window.navigator.clipboard.writeText(window.location.href);
                  
                }}
                
                />
              </div>

              </div>
              
              <div
              className='big-screen-bag'
              >
                <AddToBag 
                pressed={addProductToCart} 
                increase={()=>increaseQuantity(product.data.id)} 
                decrease={()=>decreaseQuantity(product.data.id)} 
                
                item={product.data}
                inCart={inCart(product.data.id)}
                params={params}
                />
              </div>
              
            </div>
            {/*<div className="seller_contacts" >
              <CustomButton
              title={"Contact seller"}
              backgroundColor={"var(--app-color)"}
              borderRadius={20}
              accessoryLeft={<FaPhone size={20} />}
            />
            <CustomButton
              title={"Email seller"}
              backgroundColor={"#225ea3"}
              borderRadius={20}
              accessoryLeft={<FaMailBulk size={20} />}
            />
            <CustomButton
              title={"View page"}
              pressAction={viewShop}
              backgroundColor={"#225ea3"}
              borderRadius={20}
              accessoryLeft={<FaSitemap size={20} />}
            />
            </div>
            <p> <FaShare size={20} /> Share</p>
            */}
          </div>

        </div>
      </div>:
        <div
         className="product_container"
        >
          Ooops! Product not found!
        </div>
      }

      

      <div
        className="related_products"
      >
        <HeaderHero heading={"You may also like"}
        subTitle={"Recomended"} toUrl={`/products`}/>
        <HorizontalProductsBanner url={APPDATAURLS.random}/>

        
        {
          product.data?.companyID?
          <>
          <HeaderHero heading={"More from shop"}
          subTitle={product.data?.companyName} toUrl={`/company/${product.data?.companyName}/${product.data?.companyID}`}/>
          <ProductsBanner url={`${APPDATAURLS.companyProducts}${product.data?.companyID}`} />
          </>:
          <></>
        }
      </div>

      

      <div
        className='small-screen-bag'
      >
        <AddToBag pressed={addProductToCart} increase={()=>increaseQuantity(product.data.id)} decrease={()=>decreaseQuantity(product.data.id)} 
        item={product.data}
        inCart={inCart(product.data.id)}
        params={params}
        />
      </div>
      
    <Footer/>
    </div>
  )
}

export default ProductPage