import React, { useEffect, useRef, useState } from 'react';
import "./index.css";
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import CustomButton from '../CustomButton';
import { APPDATAURLS, BASEURL } from '../../utils/APPADATAURLS';
import Lottie from 'lottie-react';
import { useNavigate } from 'react-router-dom';

const Banner = ({ marginTop = 0 }) => {
  const bannerRef = useRef(null);
  const imageRef = useRef(null);
  const navigate = useNavigate();
  const [visibleArrow, setVisibleArrow] = useState({
    right: false,
    left: true,
    scrolledWidth: 0
  });
  const [adverts, setAdverts] = useState({
    data: [],
    loading: false,
    error: false,
    message: ""
  });
  const [imageLoaded, setImageLoaded] = useState(false);
  const [carouselTimeout, setCarouselTimeout] = useState(3000); // default for images
  const [currentIndex, setCurrentIndex] = useState(0);
  const videoRefs = useRef([]); // For tracking multiple video refs

  const fetchAdverts = () => {
    setAdverts({
      data: [],
      loading: true,
      error: false,
      message: ""
    });
    fetch(APPDATAURLS.adverts, {
      method: "get"
    }).then(req => req.json()).then(response => {
      setAdverts({
        data: response.data,
        loading: false,
        error: response.error,
        message: response.message
      });
    }).catch(e => {
      console.log(e);
      setAdverts({
        data: [],
        loading: false,
        error: true,
        message: "An error occurred. Try again later"
      });
    });
  };

  const updateCarouselTimeout = (product) => {
    if (product.showProductVideo == 1 && videoRefs.current[currentIndex]) {
      // If it's a video, update the timeout based on the video duration
      const videoDuration = videoRefs.current[currentIndex].duration * 1000 || 5000; // Fallback to 5 seconds if duration is not available
      setCarouselTimeout(videoDuration);
    } else {
      setCarouselTimeout(3000); // Default for images
    }
  };

  // Handle infinite loop behavior
  const handleInfiniteScroll = () => {
    const totalItems = adverts.data.length;
    if (currentIndex === totalItems) {
      // If we're at the duplicate of the first slide, jump to the real first slide
      setTimeout(() => {
        bannerRef.current.scrollLeft = 0;
        setCurrentIndex(0);
      }, 0);
    } else if (currentIndex === -1) {
      // If we're at the duplicate of the last slide, jump to the real last slide
      setTimeout(() => {
        const dimensions = imageRef.current.getBoundingClientRect();
        bannerRef.current.scrollLeft = dimensions.width * (totalItems - 1);
        setCurrentIndex(totalItems - 1);
      }, 0);
    }
  };

  useEffect(() => {
    if (imageRef?.current) {
      let container_dimensions = imageRef.current.getBoundingClientRect();

      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => {
          const nextIndex = prevIndex + 1;
          if (nextIndex >= adverts.data.length + 1) {
            handleInfiniteScroll();
            return prevIndex; // Don't change the index here, will be reset in `handleInfiniteScroll`
          }
          updateCarouselTimeout(adverts.data[prevIndex]); // Update timeout for video or image
          bannerRef.current.scrollLeft = (container_dimensions.width * nextIndex);
          return nextIndex;
        });
      }, carouselTimeout); // Interval is now dynamic based on video/image

      return () => clearInterval(interval); // Clear interval on unmount
    }
  }, [adverts, carouselTimeout, currentIndex]);

  // Use IntersectionObserver to autoplay/pause videos when in view
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          const videoElement = entry.target;
          if (entry.isIntersecting && videoElement.tagName === 'VIDEO') {
            videoElement.play();
          } else if (!entry.isIntersecting && videoElement.tagName === 'VIDEO') {
            videoElement.pause();
            
          }
        });
      },
      { threshold: 0.5 } // Video should be at least 50% in view to trigger play/pause
    );

    videoRefs.current.forEach((video) => {
      if (video) observer.observe(video);
    });

    return () => {
      videoRefs.current.forEach((video) => {
        if (video) observer.unobserve(video);
      });
    };
  }, [adverts]);

  useEffect(() => {
    fetchAdverts();
  }, []);

  return (
    <div 
      className='banner'
      style={{ marginTop }}
    >
      <div className='banner-move-button banner-prev'>
        <FaChevronLeft 
          size={30} 
          fill={"var(--app-color)"}
          onClick={() => {
            const dimensions = imageRef.current.getBoundingClientRect();
            setCurrentIndex(currentIndex - 1);
            bannerRef.current.scrollLeft -= dimensions.width;
            handleInfiniteScroll();
          }}
        />
      </div>
      <div className='banner-move-button banner-next'>
        <FaChevronRight 
          size={30} 
          fill={"var(--app-color)"}
          onClick={() => {
            const dimensions = imageRef.current.getBoundingClientRect();
            setCurrentIndex(currentIndex + 1);
            bannerRef.current.scrollLeft += dimensions.width;
            handleInfiniteScroll();
          }}
        />
      </div>
      <div 
        className='banner-image-description'
        ref={bannerRef}
      >
        {
          adverts.error ?
            <div className='banner-image-banner'>
              <Lottie className='banner-image' loop animationData={require("../../assets/lottie_animations/no-internet.json")} />
            </div> :
            adverts.loading ?
              <div className='banner-image-banner'>
                <Lottie className='banner-image' loop animationData={require("../../assets/lottie_animations/progress.json")} />
              </div> :
              adverts.data.length > 0 ?
                [
                  ...adverts.data.map((product, index) => (
                    <div 
                      className='banner-image-banner' 
                      ref={imageRef} 
                      key={index}
                    >
                      {
                        product.showProductVideo == 1 ?
                          <video
                            ref={(el) => videoRefs.current[index] = el} // Reference to video
                            muted
                            className='banner-image'
                            
                            onLoadedMetadata={() => {
                              updateCarouselTimeout(product); // Update on video load
                              setImageLoaded(true);
                            }}
                            onError={() => setImageLoaded(false)}
                            style={{}}
                            src={`${BASEURL}/${product.productVideo}`}
                          /> :
                          <img
                            className='banner-image'
                            onLoad={() => setImageLoaded(true)}
                            onError={() => setImageLoaded(false)}
                            style={{}}
                            src={`${BASEURL}/${product.productImage}`}
                          />
                      }
                      <div
                        style={{
                          position: "absolute",
                          bottom: 0,
                          width: "100%",
                          padding: "5%",
                          backgroundColor: "#99999999"
                        }}
                      >
                        <p style={{
                          color: "#ffffff",
                          fontSize: 20,
                          fontWeight: "bolder"
                        }}>
                          {product.productName}
                        </p>
                        <CustomButton
                          pressAction={() => navigate(`/products/${product.productName}/${product.productID}`)}
                          width={100}
                          title={"Check"}
                          backgroundColor={"var(--app-color)"}
                          borderRadius={20}
                        />
                      </div>
                    </div>
                  )),
                  // Clone the first slide at the end for the infinite scroll effect
                  <div 
                    className='banner-image-banner' 
                    ref={imageRef} 
                    key="clone-first"
                  >
                    {
                      adverts.data[0].showProductVideo == 1 ?
                        <video
                          ref={(el) => videoRefs.current[adverts.data.length] = el} // Reference to clone
                          muted
                          className='banner-image'
                          style={{}}
                          src={`${BASEURL}/${adverts.data[0].productVideo}`}
                        /> :
                        <img
                          className='banner-image'
                          style={{}}
                          src={`${BASEURL}/${adverts.data[0].productImage}`}
                        />
                    }
                  </div>
                ] :
                <div className='data-holder'>
                  There is nothing here.
                </div>
        }
      </div>
    </div>
  );
}

export default Banner;
