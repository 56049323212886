import React, { useEffect } from "react";
import { BASEURL } from "../utils/APPADATAURLS";

const useSEO = (props) => {
  const setSEO = () => {
    document.title = props.title ?? "SAVOWAREHOUSE";

    var link = document.querySelector("link[rel='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.head.appendChild(link);
    }
    link.href = props.image!=""?`${BASEURL}/${props.image}`:"/assets/images/savo_logo.jpg";
  };
  useEffect(() => {
    setSEO();
  }, [props]);
};

export default useSEO;
